import { Col, Row } from 'antd';
import Image from 'next/image';
import { StyledTrustedWorldWide } from './TrustedWorldWide.styled';

const schools = [
  {
    title: 'French American School',
    description: 'New York, USA',
    image: '/img/home/fasny.svg',
  },
  {
    title: 'Salusbury Primary School',
    description: 'London, UK',
    image: '/img/home/salusbury.png',
  },
  {
    title: 'Marin Country Day School',
    description: 'San Francisco, USA',
    image: '/img/home/marin.svg',
  },
  {
    title: 'Aitken Creek Primary School',
    description: 'Victoria, Australia',
    image: '/img/home/aitken.png',
  },
];

export default function TrustedWorldWide() {
  return (
    <StyledTrustedWorldWide className="section trusted">
      <div className="section-content-wrapper">
        <h2 className="section-title">Trusted worldwide</h2>
        <div className="text">
          <p>
            Rockalingua is used by thousands of schools and families around the
            world.
          </p>
        </div>
        <Row>
          {schools.map((school, index) => (
            <Col xs={12} sm={6} key={index}>
              <div className="school" key={index}>
                <div className="school-image">
                  <Image
                    src={
                      process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                      school.image
                    }
                    alt={school.title}
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
                <p className="school-title emphasized">{school.title}</p>
                <p className="school-description">{school.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </StyledTrustedWorldWide>
  );
}
