import { StyledPromoVideo } from './PromoVideo.styled';
import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Image from 'next/image';

export default function PromoVideo() {
  const videoRef = useRef();
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handleFullScreen = useFullScreenHandle();

  const onFullscreenChange = (state) => {
    setIsFullScreen(state);
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <StyledPromoVideo isFullScreen={isFullScreen}>
      <FullScreen handle={handleFullScreen} onChange={onFullscreenChange}>
        {isClient ? (
          <ReactPlayer
            url={`${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_HOST}/videoTutorials/MAINpanel_VID_2024.mp4`}
            playing={playing}
            controls={false}
            muted={muted}
            loop={true}
            width="100%"
            height="auto"
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            playsinline
            ref={videoRef}
          />
        ) : (
          <video
            className="promo-video"
            controls
            poster={`${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_HOST}/videoTutorials/promo-video-2023-poster.jpg`}
            width="100%"
            autoPlay
            muted
            playsInline
          >
            <source
              url={`${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_HOST}/videoTutorials/MAINpanel_VID_2024.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
      </FullScreen>
      <div className="video-controls">
        {playing ? (
          <PlayerButton
            iconSrc={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/player/01_PAUSE.svg'
            }
            onClick={() => setPlaying(false)}
            alt="Pause"
          />
        ) : (
          <PlayerButton
            iconSrc={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/player/02_PLAY.svg'
            }
            onClick={() => setPlaying(true)}
            alt="Play"
          />
        )}
        {muted ? (
          <PlayerButton
            iconSrc={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/player/08_sonidoOFF_1.svg'
            }
            onClick={() => setMuted(false)}
            alt="Volume Off"
          />
        ) : (
          <PlayerButton
            iconSrc={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/player/07_sonidoON_1.svg'
            }
            onClick={() => setMuted(true)}
            alt="Volume Off"
          />
        )}
        {/* <PlayerButton
          iconSrc={'/img/home/player/03_PANTALLA_1.svg'}
          onClick={(e) => enterFullScreen(e, handleFullScreen, videoRef)}
          alt="Full screen"
        /> */}
      </div>
    </StyledPromoVideo>
  );
}

function PlayerButton({ onClick, iconSrc, alt }) {
  return (
    <button onClick={onClick} className="player-button">
      <div className="icon-container">
        <Image src={iconSrc} alt={alt} layout="fill" />
      </div>
    </button>
  );
}

async function enterFullScreen(e, handleFullScreen, videoRef) {
  try {
    await handleFullScreen.enter();
  } catch (e) {
    try {
      const element = document.body;
      const requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullscreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullscreen;

      if (requestMethod) {
        requestMethod.apply(element);
      } else if (videoRef.current.getInternalPlayer().webkitEnterFullScreen) {
        videoRef.current.getInternalPlayer().webkitEnterFullScreen();
      }
    } catch (e) {
      alert(e);
    }
  }
}
