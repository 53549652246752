import styled from 'styled-components';

export const StyledHomeCarousel = styled.div`
  .ant-carousel .home-carousel .slick-next {
    right: 0;
    z-index: 10;
  }
  .ant-carousel .home-carousel .slick-prev {
    left: 0;
    z-index: 10;
  }
`;
