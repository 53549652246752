import { Paragraph, Title } from '@/shared/Typography';
import Image from 'next/image';
import { StyledTestimonials } from './Testimonials.styled';

export default function Testimonials() {
  return (
    <StyledTestimonials className="section testimonials">
      <div className="section-content-wrapper">
        <Title level={2} className="section-title">
          Helping Spanish teachers thrive since 2013
        </Title>
        <div className="opening-image-wrapper">
          <Image
            src={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/illustrations/teacher-woman.png'
            }
            alt="Happy teacher"
            layout="fill"
            objectFit="contain"
          />
        </div>
        <div className="testimonials-list">
          <div className="testimonial">
            <Paragraph className="testimonial-text" italic>
              <q>
                My students absolutely love Rockalingua! Their eyes are glued to
                the screen, the animation makes them laugh, and they have so
                much fun practicing their Spanish! They want to continue singing
                even after we finish!
              </q>
            </Paragraph>
            <Paragraph className="author">
              Sofia Petruzzelli, Marymount School, New York
            </Paragraph>
          </div>
          <div className="testimonial">
            <Paragraph className="testimonial-text" italic>
              <q>
                Rockalingua makes my life as a teacher much easier. The songs
                are fun and engaging, and all the related workshees and games
                help me to plan the lessons in no time! My students love all the
                catchy songs and even the parents have reported that they are
                singing them back at home!
              </q>
            </Paragraph>
            <Paragraph className="author">
              Silvia Sánchez, Downsview Primary School, London.
            </Paragraph>
          </div>
          <div className="testimonial">
            <Paragraph className="testimonial-text" italic>
              <q>
                Rockalingua is the best website for Spanish teachers and
                students that I've used. My students can't stop singing the
                songs, and even their parents know the lyrics!
              </q>
            </Paragraph>
            <Paragraph className="author">
              Luis Giron-López, Bettie Weaver Elementary, Virginia.
            </Paragraph>
          </div>
        </div>
        <div className="closing-image-wrapper">
          <Image
            src={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/illustrations/teacher-man.png'
            }
            alt="Happy teacher"
            layout="fill"
            objectFit="contain"
          />
        </div>
      </div>
    </StyledTestimonials>
  );
}
