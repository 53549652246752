import { Typography } from 'antd';
import styled from 'styled-components';
import { baseTextStyles } from './common.styled';

const { Title } = Typography;

export const StyledTitle = styled(Title)`
  ${baseTextStyles}

  &.ant-typography {
    color: ${(props) => props.color};
  }
`;
