import Colors from '@/constants/Colors';
import { Tag } from 'antd';
import IcoMoon from 'react-icomoon';
import iconSet from '../../../../fonts/icomoon/selection.json';
import { StyledActivityIcon } from './ActivityTypeIcon.styled';

const Icon = (props) => <IcoMoon iconSet={iconSet} {...props} />;

export default function ActivityTypeIcon({
  type,
  color = 'black',
  size = 18,
  withCircle,
  circleColor = Colors.lightGray,
  className,
  freeLabel,
  width = '',
  height = '',
}) {
  const icon = <Icon icon={normalizedType(type)} size={size} color={color} />;
  return withCircle ? (
    <StyledActivityIcon
      className={className}
      color={circleColor}
      style={{ width, height }}
    >
      {icon}
      {freeLabel ? (
        <Tag color={Colors.green} className="free-tag">
          FREE
        </Tag>
      ) : null}
    </StyledActivityIcon>
  ) : (
    icon
  );
}

function normalizedType(type) {
  if (type == 'external') {
    return 'video';
  }
  if (type == 'storyWithQuestions') {
    return 'shortStory';
  }
  return type;
}
