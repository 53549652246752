import Button from '@/shared/Button/Button';
import { StyledFirstWidget } from './FirstWidget.styled';
import PromoVideo from './PromoVideo/PromoVideo';

export default function FirstWidget() {
  return (
    <StyledFirstWidget>
      <div className="section-content-wrapper">
        <div className="column">
          <h1 className="main-claim">
            The fun way <br />
            to learn Spanish
          </h1>
          <div className="second-row">
            <div className="second-row-left">
              <h2 className="sub-claim">Videos, songs, games and much more!</h2>
              <Button type="primary" href="/sign-up" size="normal">
                Start free trial
              </Button>
            </div>
          </div>
        </div>
        <div className="column">
          <PromoVideo />
        </div>
      </div>
    </StyledFirstWidget>
  );
}
