import ActivityTypes from '@/constants/ActivityTypes';
import ActivityTypeIcon from '@/shared/ActivityTypeIcon/ActivityTypeIcon';
import Link from 'next/link';
import {
  StyledActivityIconCircle,
  StyledActivityTypesList,
} from './ActivityTypesList.styled';
import { pxToVw } from '@/modules/styled/cssUnits';
import { PlusOutlined } from '@ant-design/icons';

export default function ActivityTypesList({ showMore }) {
  const activityTypes = ActivityTypes.withLabels;
  return (
    <StyledActivityTypesList className="activities-list">
      <Link href={'/themes'}>
        <a className="activity-type">
          <StyledActivityIconCircle themes type={'themes'}>
            <ActivityTypeIcon
              type={'themes'}
              color={'white'}
              size={pxToVw(24)}
            />
          </StyledActivityIconCircle>
          <span className="activity-type-name">Themes</span>
        </a>
      </Link>
      {activityTypes.map((activityType) => {
        return (
          <Link
            href={`/${ActivityTypes.toSlug[activityType.value]}`}
            key={activityType.value}
          >
            <a className="activity-type">
              <StyledActivityIconCircle type={activityType.value}>
                <ActivityTypeIcon
                  type={activityType.value}
                  color={'white'}
                  size={pxToVw(24)}
                />
              </StyledActivityIconCircle>
              <div className="activity-type-name">{activityType.label}</div>
            </a>
          </Link>
        );
      })}
      {/* {showMore && (
        <Link href={'/themes'}>
          <a className="activity-type">
            <StyledActivityIconCircle type={'more'}>
              <PlusOutlined className="more" />
            </StyledActivityIconCircle>
            <div className="activity-type-name">Much more!</div>
          </a>
        </Link>
      )} */}
    </StyledActivityTypesList>
  );
}
