import { useEffect, useState, useContext } from 'react';
import { GlobalContext } from '@/shared/GlobalContext/GlobalContext';
import getLatestActivities from '@/api/content/getLatestActivities.endpoint';

export default function useHome() {
  const context = useContext(GlobalContext);

  const [state, setState] = useState({
    latestActivities: [],
    promoModalOpen: false,
  });

  const actions = {
    setState: (newState) => {
      setState((previousState) => ({ ...previousState, ...newState }));
    },
    openPromoModal: () => {
      actions.setState({ promoModalOpen: true });
    },
    closePromoModal: () => {
      actions.setState({ promoModalOpen: false });
    },
    loadLatestActivities: async () => {
      const latestActivities = await getLatestActivities();
      actions.setState({ latestActivities });
    },
  };

  /*   useEffect(() => {
    actions.loadLatestActivities();
  }, [context.state.loaded]); */

  return { state, actions };
}
