import styled from 'styled-components';
import Colors from '@/constants/Colors';
import { pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const StyledStamps = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${pxToVw(50)};

  .money-back {
    display: flex;
    align-items: center;
    position: relative;
    aspect-ratio: 1;
    width: ${pxToVw(60)};
  }
  .ssl {
    display: flex;
    align-items: center;
    position: relative;
    height: ${pxToVw(60)};
    svg {
      color: ${Colors.primary};
      font-size: ${pxToVw(30)};
    }
    .ssl-stripe-logo {
      position: relative;
      width: ${pxToVw(65)};
      height: ${pxToVw(55)};
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    .money-back {
      width: 35px;
    }
    .ssl {
      height: 45px;
      svg {
        font-size: 20px;
      }
      .ssl-stripe-logo {
        width: 45px;
        height: 35px;
      }
    }
  }
`;
