import styled from 'styled-components';
import { pxToVw } from '@/modules/styled/cssUnits';
import Colors from '@/constants/Colors';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const StyledLms = styled.div`
  &.section.lms {
    margin-top: ${pxToVw(140)};
    position: relative;
    background: ${Colors.backgroundPaleBlue};

    .section-content-wrapper {
      height: 100%;

      .section-title-wrapper {
        padding-left: 22%;
        padding-top: 10%;
        z-index: 1;
      }
    }

    .details {
      margin-top: 20px;
      padding-left: 22%;
      padding-right: 35%;

      .details-title {
        color: ${Colors.textDark};
        margin-bottom: 10px;
        text-align: center;
        line-height: 26px;
      }

      .details-features {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-end;
        padding-right: 40px;

        .feature {
          color: ${Colors.primary};
          display: flex;
          align-items: center;
          text-align: right;
          gap: 10px;
        }
      }
    }

    .image-wrapper {
      position: relative;
      width: calc(100% - 25%);
      z-index: 0;
      margin-top: -220px;
      margin-left: 25%;
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    &.section.lms {
      .section-content-wrapper .section-title-wrapper {
        padding-top: 40px !important;
        padding-left: 0;
        padding-top: 0;
      }

      .details {
        padding-left: 0;
        padding-right: 0;

        .details-features {
          padding-right: 0;
        }
      }

      .image-wrapper {
        margin-top: 0px;
        margin-left: 0;
        width: 100%;
      }
    }
  }
`;
