import PublicPage from '../../../shared/PublicPage/PublicPage';
import { StyledHome } from './Home.styled';
import useHome from './useHome.hook';
import { WithTwoDropsBg } from '@/shared/PublicPage/CustomBgPublicPage.styled';
import FirstWidget from './FirstWidget/FirstWidget';
import ActivityTypes from './ActivityTypes/ActivityTypes';
import HowDoesItWork from './HowDoesItWork/HowDoesItWork';
import Interactive from './Interactive/Interactive';
import Printable from './Printable/Printable';
import TrustedWorldWide from './TrustedWorldWide/TrustedWorldWide';
import Testimonials from './Testimonials/Testimonials';
import Lms from './Lms/Lms';
import Support from './Support/Support';
import Member from './Member/Member';

export default function Home() {
  const { state, actions } = useHome();
  return (
    <PublicPage
      withFooter
      noPadding
      noMaxWidth
      StyledPublicPage={WithTwoDropsBg}
      className="home"
    >
      <StyledHome>
        <FirstWidget />
        <ActivityTypes />
        <HowDoesItWork actions={actions} />
        <TrustedWorldWide />
        <Interactive />
        <Printable />
        <Testimonials />
        <Lms />
        <Support />
        <Member />
      </StyledHome>
    </PublicPage>
  );
}
