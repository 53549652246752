import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import { pxToVw } from '@/modules/styled/cssUnits';
import styled from 'styled-components';

export const StyledSocialNetworks = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToVw(30)};
  a {
    display: flex;
    align-items: center;
    position: relative;
    aspect-ratio: 1;
    width: ${pxToVw(40)};
  }
  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    margin-top: 10px;
    .social-media {
      padding: 20px 0px 10px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      a {
        width: 24px;
      }
    }
  }
`;
