import Colors from '@/constants/Colors';

export default function Trapezoid({
  fillColor = Colors.backgroundPaleBlue,
  width = '100%',
  height = '100%',
  points,
}) {
  return (
    <svg
      width={width}
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
    >
      {points && <polygon points={points} fill={fillColor} />}
    </svg>
  );
}
