import ActivityTypesList from '@/shared/ActivityTypesList/ActivityTypesList';
import { Paragraph, Title } from '@/shared/Typography';
import { StyledActivityTypes } from './ActivityTypes.styled';

export default function ActivityTypes() {
  return (
    <StyledActivityTypes className="section activity-types">
      <div className="section-content-wrapper">
        <h2 className="section-title">
          Everything you need for a successful school year!
        </h2>
        <Title level={2} className="spanish-for-kids-title">
          Spanish for kids
        </Title>
        <Paragraph className="spanish-for-kids">
          Enjoy teaching Spanish with our kid-friendly games, songs, worksheets
          and more! Rockalingua has hundreds of interactive activities that are
          ready to use.
        </Paragraph>

        <ActivityTypesList showMore />
      </div>
    </StyledActivityTypes>
  );
}
