import { Paragraph, Title } from '@/shared/Typography';
import Image from 'next/image';
import { StyledSupport } from './Support.styled';

export default function Support() {
  return (
    <StyledSupport className="section support">
      <div className="section-content-wrapper">
        <Title level={2} className="section-title">
          You’re not alone!
        </Title>
        <div className="subsections">
          <div className="support-subsection">
            <Title level={2}>We are here to help you.</Title>
            <div className="support-subsection-content">
              <div className="support-image-wrapper">
                <Image
                  src={
                    process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                    '/img/home/illustrations/support.png'
                  }
                  alt="Expert support team"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
              <div className="contact-us">
                <Paragraph>
                  For general info email us at <br />
                  info@rockalingua.com.
                </Paragraph>
                <Paragraph>
                  For any technical issues or questions email us at <br />
                  support@rockalingua.com.
                </Paragraph>
                <Paragraph>
                  Prefer to chat?
                  <br />
                  Give us a call at +1 415 713 3537
                  <br />
                  An actual human will answer the phone!
                </Paragraph>
              </div>
            </div>
          </div>
          <div className="success-subsection">
            <Title level={2} className="success">
              Your success is our success!
            </Title>
            <div className="success-image-wrapper">
              <Image
                src={
                  process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                  '/img/home/illustrations/success.png'
                }
                alt="Expert support team"
                layout="fill"
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
    </StyledSupport>
  );
}
