import styled from 'styled-components';
import Colors from '@/constants/Colors';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import { pxToVw, maxWidth } from '@/modules/styled/cssUnits';

const imgFolder = process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + '/img';

export const StyledPrintable = styled.div`
  margin-top: ${pxToVw(40)};

  .not-only-screen-wrapper {
    padding: 0 calc(((100% - ${maxWidth}px) / 2));

    .not-only-screen-image {
      margin-left: 150px;
      height: 180px;
      width: 250px;
      position: relative;
      margin-bottom: -40px;
    }
  }
  .section.printable {
    .section-content-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .first-row {
        display: flex;
        gap: 20px;
        flex: 1;

        .main-panel {
          flex: 1;
          position: relative;

          .image-wrapper {
            height: 100%;
            width: 100%;
            position: relative;
            z-index: 5;
            img {
              object-fit: contain;
            }

            &.notes {
              position: absolute;
              top: -25%;
              left: -10%;
              width: 100%;
              z-index: 4;
            }
          }
        }

        .section-titles-container {
          flex: 1;
          display: flex;
          justify-content: center;

          .section-titles {
            display: flex;
            flex-direction: column;

            .section-title {
              color: ${Colors.primary};
              font-size: var(--font-size-h1);
              text-transform: none;
            }

            .section-subtitle {
              font-size: var(--font-size-h3);
              line-height: var(--line-height-h3);
              z-index: 7;
            }

            .button-container {
              margin-top: 20px;
              text-align: center;

              button {
                z-index: 10;
              }
            }

            .carousel-buttons {
              margin-top: 30px;
            }

            .section-subtitle2 {
              margin: 20px 40px 0;
              text-align: center;
            }
          }
        }
        .drop-background {
          background-image: url('${imgFolder}/public-page/drop.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transform: scale(1.2);
        }
      }

      .images-container {
        width: 100%;
        height: 35vh;
        display: flex;
        overflow: visible !important;
        margin-top: -10px;

        .image-wrapper {
          height: 100%;
          width: 100%;
          position: relative;
          margin-left: -50px;

          &:first-child {
            margin-top: -35px;
            margin-left: 0;
          }

          span {
            overflow: visible !important;

            img {
              transform: scale(1.5);
              object-fit: contain;
            }
          }
        }
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        display: flex;
        justify-content: center;
        padding: 0 ${pxToVw(80)};
      }
    }

    .big-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      .icon {
        z-index: 10;
        width: 150px;
        height: 150px;
        border: 10px solid white;
      }
    }

    &.worksheets {
      .images-container {
        height: 55vh;
        .image-wrapper span img {
          transform: scale(1.5);
        }
      }
    }

    &.worksheets {
      .images-container {
        height: 55vh;
        .image-wrapper span img {
          transform: scale(1.5);
        }
      }
    }

    &.picture-dictionaries {
      .images-container {
        .image-wrapper:first-child {
          margin-top: 0;
          margin-left: 5%;
          span img {
            transform: scale(1.5);
          }
        }
        .image-wrapper:nth-child(2) {
          margin-left: -55%;
          span img {
            transform: scale(1.3);
          }
        }
        .image-wrapper:last-child {
          margin-left: -25%;
          span img {
            transform: scale(1.5);
          }
        }
      }
    }

    &.short-stories {
      .title-with-icon {
        margin-left: -40px;
        display: flex;
        align-items: center;
        gap: 20px;

        .title-parts {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 10px;

          span:last-child {
            margin-left: 100px;
          }
        }
      }

      .images-container {
        .image-wrapper:first-child {
          margin-top: -10%;
          margin-left: 5%;
          span img {
            transform: scale(1.5);
          }
        }
        .image-wrapper:nth-child(2) {
          margin-top: -7%;
          margin-left: -55%;
          span img {
            transform: scale(1.3);
          }
        }
        .image-wrapper:last-child {
          margin-left: -25%;
          span img {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    .not-only-screen-wrapper {
      text-align: center;
      .not-only-screen-image {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .section.printable {
      margin-top: 40px;
      .section-content-wrapper {
        .first-row {
          flex-direction: column-reverse;

          .main-panel {
            height: 300px;
            transform: scale(1);

            .image-wrapper {
              height: 300px;
            }

            &.drop-background {
              transform: scale(1);
            }
          }

          .section-titles-container {
            padding: 20px;
          }
        }

        .images-container {
          margin-top: -120px;
          width: 100%;
          display: flex;
        }
      }

      .big-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;

        .icon {
          width: 100px !important;
          height: 100px !important;

          svg {
            width: 15px !important;
            height: 15px !important;
          }
        }
      }

      &.picture-dictionaries {
        .big-icon-wrapper {
          margin-bottom: 80px;
        }
      }

      &.short-stories {
        .big-icon-wrapper {
          margin-bottom: 100px;
        }

        .title-with-icon {
          margin-left: 0;
          flex-direction: column;

          .big-icon-wrapper {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;
