import { useEffect, useRef, useState } from 'react';

export default function useBrightness() {
  const [brightness, setBrightness] = useState(0);
  const imageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const startingPoint = windowHeight;
        if (rect.top <= startingPoint) {
          const scrollProgress = Math.min(
            Math.max((startingPoint - rect.top) / startingPoint, 0),
            1
          );
          setBrightness(scrollProgress * 100);
        } else if (rect.top > startingPoint + 100) {
          setBrightness(0);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { imageRef, brightness };
}
