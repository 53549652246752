import Image from 'next/image';
import { StyledStamps } from './Stamps.styled';
import { LockOutlined } from '@ant-design/icons';

export default function Stamps() {
  return (
    <StyledStamps>
      <span className="ssl">
        <LockOutlined />
        <div className="ssl-stripe-logo">
          <Image
            src={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/stripe.svg'
            }
            alt="Stripe"
            layout="fill"
          />
        </div>
      </span>
      <span className="money-back">
        <Image
          src={
            process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
            '/img/stamp-money-back.svg'
          }
          alt="Money back guarantee"
          layout="fill"
        />
      </span>
    </StyledStamps>
  );
}
