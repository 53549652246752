import styled from 'styled-components';
import { pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const StyledSupport = styled.div`
  &.section.support {
    .section-content-wrapper {
      height: 100%;
      .section-title {
        font-size: calc(var(--font-size-h1));
        line-height: calc(var(--line-height-h1));
      }
      .subsections {
        height: 100%;
        display: flex;
        justify-content: space-between;
        gap: ${pxToVw(40)};

        .support-subsection {
          height: 100%;
          flex: 1;
          position: relative;

          .support-subsection-content {
            display: flex;
            align-items: center;
            padding-top: ${pxToVw(70)};

            .contact-us {
              flex: 1;
              .ant-typography {
                font-size: var(--font-size-h3);
              }
            }

            .support-image-wrapper {
              position: relative;
              width: ${pxToVw(300)};
              min-height: ${pxToVw(250)};

              img {
                object-fit: contain;
              }
            }
          }
        }
        .success-subsection {
          height: 100%;
          flex: 1;
          h2 {
            margin-top: ${pxToVw(250)};
            text-align: right;
            font-size: var(--font-size-h2);
            line-height: var(--line-height-h2);
          }

          .success-image-wrapper {
            margin-top: 40px;
            position: relative;
            min-height: ${pxToVw(400)};

            img {
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    &.section.support {
      .section-content-wrapper .subsections {
        flex-direction: column;

        .support-image-wrapper {
          width: auto;
          flex: 1;
          height: 200px;
        }

        .success-subsection {
          h2 {
            margin-top: 40px;
          }
          .success-image-wrapper {
            text-align: right;
            height: 200px;
          }
        }
      }
    }
  }
`;
