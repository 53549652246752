import { useState, useEffect, useRef } from 'react';

export default function ScrollAppearingDiv({ className = '', children }) {
  const [top, setTop] = useState('100%');
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const startingPoint = windowHeight * 2;

        if (rect.top <= startingPoint) {
          const scrollProgress = Math.min(
            Math.max((startingPoint - rect.top) / startingPoint, 0),
            1
          );
          const newTop = 100 - scrollProgress * 100;
          setTop(`${newTop}%`);
        } else if (rect.top > startingPoint + 100) {
          setTop('100%');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={className}
      style={{
        top,
        transition: 'height 0.3s ease',
      }}
    >
      {children}
    </div>
  );
}
