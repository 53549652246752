import styled from 'styled-components';
import Colors from '@/constants/Colors';
import { maxWidth, pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const StyledMember = styled.div`
  &.section.member {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    .section-content-wrapper {
      position: relative;
      padding-left: 0;
      padding-right: 0;
      .text-wrapper {
        position: absolute;
        top: ${pxToVw(10)};
        left: 0;
        right: 0;
        z-index: 10;
        text-align: center;

        .section-title {
          font-size: calc(var(--font-size-h1));
          line-height: calc(var(--line-height-h1));
          text-transform: uppercase;
        }
        h3 {
          color: ${Colors.text};
          text-transform: uppercase;
          font-size: var(--font-size-h3);
          margin-top: ${pxToVw(10)};
          padding: 0 30%;
        }
        a {
          margin-top: ${pxToVw(50)};
        }
      }
      .member-image-wrapper {
        padding-top: 120px;
        width: 100%;
        aspect-ratio: calc(1280 / 624);

        img {
          object-fit: contain;
        }
      }

      .logos-container {
        position: absolute;
        padding: ${pxToVw(20)} ${pxToVw(30)};
        width: 100%;
        bottom: 0;
        display: flex;
        justify-content: space-between;
      }

      .sun {
        top: 0;
        left: max(calc(((100% - ${maxWidth}px) / 2)), 50px);
        position: absolute;
        width: ${pxToVw(198)};
        height: ${pxToVw(193)};
        z-index: -1;
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    &.section.member {
      .section-content-wrapper {
        .text-wrapper {
          .section-title {
            font-size: calc(var(--font-size-h1) + 5px);
            line-height: calc(var(--line-height-h1) + 5px);
            margin-bottom: 10px;
          }
          h3 {
            margin-bottom: 10px;
            padding: 0 5%;
          }
        }

        .logos-container {
          padding: 0 20px;
          gap: 20px;
        }

        .sun {
          margin-top: 70px;
        }
      }
    }
  }
`;
