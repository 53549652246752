import { StyledCarouselButtons } from './CarouselButtons.styled';

export default function CarouselButtons({ slideCount, currentSlide, goTo }) {
  return (
    <StyledCarouselButtons className="carousel-buttons">
      {Array.from({ length: slideCount }, (_, i) => (
        <div
          key={i}
          className={`carousel-button ${currentSlide == i ? 'active' : ''}`}
          onClick={() => goTo && goTo(i)}
        ></div>
      ))}
    </StyledCarouselButtons>
  );
}
