import { useEffect, useRef } from 'react';

export default function useScrollOverlap() {
  const beforeRef = useRef(null);
  const afterRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      try {
        if (beforeRef.current && afterRef.current) {
          const firstDivPosition =
            beforeRef.current.getBoundingClientRect().top;
          const firstDivHeight = beforeRef.current.offsetHeight;
          const windowHeight = window.innerHeight;
          const halfWindowHeight = (windowHeight + firstDivHeight / 2) / 3;

          if (firstDivPosition < halfWindowHeight) {
            const visibleHeight = (halfWindowHeight - firstDivPosition) * 2;

            const afterDivHeight = afterRef.current.offsetHeight;

            afterRef.current.style.height = `${visibleHeight}px`;
          } else {
            afterRef.current.style.height = 0;
          }
        }
      } catch (error) {
        console.error('Error in handleScroll:', error);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { beforeRef, afterRef };
}
