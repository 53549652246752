import Colors from '@/constants/Colors';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import { maxWidth, pxToVw } from '@/modules/styled/cssUnits';
import styled from 'styled-components';

export const StyledHome = styled.div`
  padding-top: var(--page-top-padding);
  font-size: var(--font-size-p);

  h1,
  h2 {
    font-weight: bold;
    color: ${Colors.primary};
  }

  .ant-btn {
    font-size: 16px;
    padding: 0px 32px;
    height: auto;
    line-height: 36px;
  }

  .section {
    padding: 0 calc((100% - ${maxWidth}px) / 2);
    margin-top: ${pxToVw(80)};

    .section-content-wrapper {
      padding: 0 ${pxToVw(80)};
      position: relative;
      width: 100%;
      .section-title {
        color: ${Colors.primary};
        text-transform: none;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
      }
      .section-subtitle {
        font-weight: bold;
        color: ${Colors.text};
      }
    }

    p.emphasized {
      font-size: 120%;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    .section {
      margin-top: ${pxToVw(80)};

      .section-content-wrapper {
        padding: 0 var(--page-lateral-padding);
        position: relative;
        width: 100%;

        .section-title {
          color: ${Colors.primary};
          text-transform: none;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
`;
