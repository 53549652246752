import styled from 'styled-components';
import { pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

const imgFolder = process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + '/img';

export const StyledTrustedWorldWide = styled.div`
  &.section.trusted {
    .section-content-wrapper {
      background-image: url('${imgFolder}/home/arrow-semicircle-right.svg');
      background-repeat: no-repeat;
      background-position: ${pxToVw(270)} ${pxToVw(-12)};
      background-size: ${pxToVw(120)};
      .section-title {
        padding-top: ${pxToVw(65)};
      }
      .text {
        margin: ${pxToVw(10)} ${pxToVw(600)} ${pxToVw(80)} 0;
      }
      .school {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        .school-image {
          position: relative;
          width: 100%;
          height: 100px;
          margin-bottom: 15px;
        }

        .school-title {
          font-weight: bold;
          margin-top: ${pxToVw(30)};
          margin-bottom: 0;
          font-size: 16px;
        }
        .school-description {
          font-size: 14px;
          margin-bottom: ${pxToVw(10)};
        }
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    &.section.trusted {
      .section-content-wrapper {
        background-position: 150px -12px;
        background-size: 75px;

        .section-title {
          padding-top: ${pxToVw(90)};
        }
        .text {
          margin: 5px 0px 20px;
        }
        .school {
          .school-image {
            width: 50%;
            margin-bottom: 0;
          }

          .school-title {
            margin-top: ${pxToVw(30)};
            text-align: center;
          }
          .school-description {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
