import styled from 'styled-components';
import Colors from '@/constants/Colors';
import { maxWidth, pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

const imgFolder = process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + '/img';

export const StyledFirstWidget = styled.div`
  padding-left: calc((100% - ${maxWidth}px) / 2);
  padding-right: calc((100% - ${maxWidth}px) / 2);

  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: ${pxToVw(20)};

  .section-content-wrapper {
    position: relative;
    width: 100%;

    padding: 0 var(--page-lateral-padding);
    min-height: ${pxToVw(325)};
    background-image: url('${imgFolder}/home/arrow-straight-right.svg');
    background-repeat: no-repeat;
    background-position: 0 ${pxToVw(25)};
    background-size: ${pxToVw(65)};
    margin-top: ${pxToVw(50)};
    display: flex;
    flex-direction: row;
    gap: ${pxToVw(40)};

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1.main-claim {
        color: ${Colors.primary};
        font-size: max(${pxToVw(70)}, 12px);
        font-weight: bold;
        line-height: ${pxToVw(85)};
        margin-bottom: 0;
        text-transform: none;
      }

      .second-row {
        flex: 1;
        display: flex;
        gap: ${pxToVw(40)};
        margin-top: ${pxToVw(20)};

        .second-row-left {
          flex: 1;
          h2.sub-claim {
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: bold;
            color: ${Colors.text};
          }
          .ant-btn {
            margin-top: ${pxToVw(30)};
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    background-position: calc(50vw - ${pxToVw(750)}) ${pxToVw(-250)};
    background-size: ${pxToVw(600)};

    .section-content-wrapper {
      background: none;
      width: 100%;
      min-height: ${pxToVw(675)};

      flex-direction: column;
      gap: ${pxToVw(120)};

      h1.main-claim {
        font-size: max(${pxToVw(100)}, 16px);
        line-height: max(${pxToVw(120)}, 18px);
        margin-top: ${pxToVw(103)};
      }
      h2.sub-claim {
        margin-top: ${pxToVw(40)};

        font-weight: bold;
        color: ${Colors.text};
      }
    }
  }
`;
