import styled from 'styled-components';
import Colors from '@/constants/Colors';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import { pxToVw } from '@/modules/styled/cssUnits';

const imgFolder = process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + '/img';

export const StyledInteractive = styled.div`
  .section.multimedia {
    .section-content-wrapper {
      position: relative;
      display: flex;
      gap: 20px;

      .section-titles-container {
        flex: 1;
        display: flex;
        justify-content: center;
        padding: 60px;
        padding-right: 0px;

        &.drop-background {
          background-image: url('${imgFolder}/public-page/drop.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        .section-titles {
          display: flex;
          flex-direction: column;
          padding-top: 20px;

          .icon-wrapper {
            display: flex;
            margin-bottom: 20px;

            .icon {
              width: 80px;
              height: 80px;
            }
          }

          .image-icon-wrapper {
            position: relative;
            height: 80px;
            width: 80px;
            margin-bottom: 20px;
          }

          .section-title {
            color: ${Colors.primary};
            font-size: var(--font-size-h1);
            text-transform: none;
          }

          .section-subtitle {
            font-size: var(--font-size-h3);
            line-height: var(--line-height-h3);
            z-index: 7;
          }

          .button-container {
            margin-top: 20px;
            text-align: center;
          }

          .carousel-buttons {
            margin-top: 30px;
          }
        }
      }

      .images-container {
        flex: 1;

        .image-wrapper {
          height: 100%;
          width: 100%;
          position: relative;

          span {
            overflow: visible !important;
          }

          img {
            object-fit: contain;
          }
        }

        &.overlapping {
          position: relative;

          .image-wrapper {
            position: absolute;
            width: 70%;
            height: 70%;
          }
          .image-wrapper:first-child {
            top: 0;
            left: 0;
            z-index: 1;
          }
          .image-wrapper:last-child {
            bottom: 0;
            right: 0;
          }
        }
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        display: flex;
        justify-content: center;
        padding: 0 ${pxToVw(80)};
      }
    }

    &.games {
      .images-container .image-wrapper img {
        transform: scale(1.1);
      }
    }

    &.comic {
      .images-container .image-wrapper img {
        transform: scale(1.5);
      }
    }

    &.hfv {
      .images-container.overlapping {
        .image-wrapper img {
          transform: scale(1.5);
        }

        .image-wrapper:first-child {
          top: -20px;
          left: -140px;
          z-index: 2;
        }
        .image-wrapper:nth-child(2) {
          top: 40px;
          right: 100px;
          z-index: 1;
        }
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    .section.multimedia {
      .section-content-wrapper {
        display: grid;
        grid-template-rows: auto auto;
        .section-titles-container {
          padding: 20px;

          .section-titles {
            .icon-wrapper .icon {
              width: 80px !important;
              height: 80px !important;

              svg {
                width: 15px !important;
                height: 15px !important;
              }
            }
          }
        }
        .images-container {
          padding: 20px;
          height: 350px;
          width: 100%;
          display: flex;
        }
      }

      &.hfv {
        .images-container.overlapping {
          .image-wrapper img {
            transform: scale(1.5);
          }

          .image-wrapper:first-child {
            left: 0px;
          }
          .image-wrapper:nth-child(2) {
            left: 50px;
            right: 0;
          }
        }
      }
    }
  }
`;
