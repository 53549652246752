import styled from 'styled-components';
import Colors from '@/constants/Colors';
import { pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const StyledActivityTypes = styled.div`
  &.section.activity-types {
    margin-top: ${pxToVw(120)};

    .spanish-for-kids-title {
      color: ${Colors.text};
      margin: 24px 0 0 0;
    }
    .spanish-for-kids {
      margin: 4px 0 60px 0;
      color: ${Colors.text};
      font-weight: normal;
      font-size: var(--font-size-h3);
      line-height: var(--line-height-h3);
    }
    h3 {
      color: ${Colors.text};
      margin-top: ${pxToVw(10)};
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    &.section.activity-types {
      margin-top: ${pxToVw(180)};
      .section-title {
        margin-bottom: 20px;
      }
      .spanish-for-kids-title {
        margin-bottom: 10px;
      }
    }
  }
`;
