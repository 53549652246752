import styled from 'styled-components';
import Colors from '@/constants/Colors';

export const StyledCarouselButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;

  .carousel-button {
    background-color: ${Colors.mediumGray};
    border: none;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;

    &.active {
      background-color: ${Colors.primary};
    }
  }
`;
