import styled from 'styled-components';
import Colors from '@/constants/Colors';
import { pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const StyledTestimonials = styled.div`
  &.section.testimonials {
    .section-content-wrapper {
      position: relative;
      .section-title {
        margin-bottom: 50px;
      }
      .opening-image-wrapper {
        position: absolute;
        top: ${pxToVw(60)};
        left: ${pxToVw(40)};
        width: ${pxToVw(175)};
        aspect-ratio: calc(724 / 1294);
      }
      .testimonials-list {
        margin-left: ${pxToVw(40)};
        margin-right: ${pxToVw(40)};
        .testimonial {
          background-color: ${Colors.backgroundPaleBlue};
          border-radius: 10px;
          margin: ${pxToVw(20)} ${pxToVw(80)};
          padding: ${pxToVw(30)} ${pxToVw(40)} ${pxToVw(10)};

          &:first-of-type {
            margin-left: 0;
            padding-left: ${pxToVw(120)};
          }

          &:last-of-type {
            margin-right: 0;
            padding-right: ${pxToVw(100)};
          }

          .testimonial-text {
            color: ${Colors.text};
            font-size: var(--font-size-h3);
            line-height: var(--line-height-h3);
          }

          .testimonial-author {
            color: ${Colors.text};
          }
        }
      }
      .closing-image-wrapper {
        position: absolute;
        bottom: ${pxToVw(-40)};
        right: ${pxToVw(40)};
        width: ${pxToVw(175)};
        aspect-ratio: calc(724 / 1294);
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    &.section.testimonials {
      .section-content-wrapper {
        .section-title {
          margin-bottom: 20px;
        }
        .opening-image-wrapper {
          top: 45px;
          left: 20px;
          width: 100px;
        }
        .testimonials-list {
          .testimonial {
            &:first-of-type {
              margin-left: 0;
              padding-left: 100px;
              text-align: right;
            }

            &:last-of-type {
              margin-right: 0;
              padding-right: 100px;
            }
          }
        }
        .closing-image-wrapper {
          bottom: 30px;
          right: 20px;
          width: 100px;
        }
      }
    }
  }
`;
