import { Typography } from 'antd';
import styled from 'styled-components';
import { baseTextStyles } from './common.styled';

const { Text } = Typography;

export const StyledText = styled(Text)`
  ${baseTextStyles}
  &.capitalize {
    text-transform: capitalize;
  }
`;
