import Colors from '@/constants/Colors';
import styled from 'styled-components';

const imgFolder = process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + '/img';

export const StyledPromoVideo = styled.div`
  position: relative;
  video {
    ${({ isFullScreen }) =>
      isFullScreen
        ? ''
        : `
      mask-image: url('${imgFolder}/home/player/01_MASK1.svg');
      mask-size: cover;
      mask-repeat: no-repeat;
    `}
  }

  .video-controls {
    position: absolute;
    bottom: 0;
    left: 0;

    .player-button {
      width: 30px;
      height: 30px;
      background: ${Colors.darkBlue};
      overflow: hidden;
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;

      .icon-container {
        position: absolute;
        width: 92px;
        height: 46px;
        left: calc(50% - 46px);
        top: calc(50% - 23px);
      }

      &:first-child {
        bottom: 8px;
        left: 10px;
      }

      &:nth-child(2) {
        bottom: -8px;
        left: 40px;
      }

      /* &:last-child {
        bottom: -28px;
        left: 75px;
      } */
    }
  }
`;
