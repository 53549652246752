import Image from 'next/image';
import { StyledInteractive } from './Interactive.styled';
import Button from '@/shared/Button/Button';
import HomeCarousel from '../HomeCarousel/HomeCarousel';
import { StyledActivityIconCircle } from '@/shared/ActivityTypesList/ActivityTypesList.styled';
import ActivityTypeIcon from '@/shared/ActivityTypeIcon/ActivityTypeIcon';
import { Title } from '@/shared/Typography';
import Trapezoid from '../Trapezoid/Trapezoid';

export default function Interactive() {
  return (
    <StyledInteractive>
      <HomeCarousel
        slides={slides}
        renderSlide={({ slide, carouselRef, carouselButtons }, index) => (
          <Slide
            key={index}
            {...slide}
            carouselRef={carouselRef}
            carouselButtons={carouselButtons}
          />
        )}
      />
    </StyledInteractive>
  );
}

function Slide({
  icon,
  iconComponent,
  title,
  subtitle,
  learnMoreButton,
  images,
  carouselRef,
  backgroundDrop,
  backgroundPoints,
  className = '',
  carouselButtons,
}) {
  return (
    <div className={`section ${className}`}>
      <div className="section-content-wrapper">
        <div
          className={`section-titles-container ${
            backgroundDrop ? 'drop-background' : ''
          }`}
        >
          <div className="section-titles">
            {icon ? (
              <div className="icon-wrapper">
                <StyledActivityIconCircle type={icon} className="icon">
                  <ActivityTypeIcon type={icon} color={'white'} size={25} />
                </StyledActivityIconCircle>
              </div>
            ) : (
              iconComponent
            )}
            <Title level={2} className="section-title">
              {title}
            </Title>
            <Title level={3} className="section-subtitle">
              {subtitle}
            </Title>
            {learnMoreButton ? (
              <div className="button-container">
                <Button
                  type="primary"
                  onClick={() => carouselRef.current.next()}
                >
                  Learn more
                </Button>
              </div>
            ) : null}
            {carouselButtons}
          </div>
        </div>
        <div
          className={`images-container ${
            images.length > 1 ? 'overlapping' : ''
          }`}
        >
          {images.map((image, index) => (
            <div key={index} className="image-wrapper">
              <Image
                src={process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + image.src}
                alt={image.alt}
                layout="fill"
                objectFit="contain"
              />
            </div>
          ))}
        </div>
        {backgroundPoints && (
          <div className="background">
            <Trapezoid points={backgroundPoints} />
          </div>
        )}
      </div>
    </div>
  );
}

const slides = [
  {
    title: (
      <>
        Multimedia content
        <br />
        your students will love.
      </>
    ),
    subtitle: (
      <>
        INTERACTIVE VIDEOS, GAMES, KARAOKE <br /> AND MUCH MORE!
      </>
    ),
    learnMoreButton: true,
    images: [
      {
        src: '/img/home/illustrations/interactive.webp',
        alt: 'Interactive content your students will love',
      },
    ],
    backgroundDrop: true,
    className: 'multimedia',
  },
  {
    icon: 'interactiveVideo',
    title: 'VIDEOS WITH QUESTIONS',
    subtitle: (
      <>
        Interactive videos that enable students to move forward by correctly
        answering key and contextual questions.
      </>
    ),
    images: [
      {
        src: '/img/home/carousels/multimedia/PAG_2_IMG_1.webp',
        alt: 'Videos with questions',
      },
      {
        src: '/img/home/carousels/multimedia/PAG_2_IMG_2.webp',
        alt: 'Key and contextual questions',
      },
    ],
    backgroundPoints: `1,25 100,5 98,100 6,90`,
    className: 'multimedia',
  },
  {
    icon: 'game',
    title: 'GAMES!',
    subtitle:
      'Fun vocabulary reinforcement games, featuring drag-and-match, concept race, and memory match activities!',
    images: [
      {
        src: '/img/home/carousels/multimedia/PAG_3_IMG_1.webp',
        alt: 'Fun vocabulary reinforcement games',
      },
    ],
    backgroundPoints: `10,21 100,5 98,90 5,75`,
    className: 'multimedia games',
  },
  {
    icon: 'realTime',
    title: 'LIVE TASK',
    subtitle:
      'A dynamic game where students listen to sentences and match them to images on their devices.',
    images: [
      {
        src: '/img/home/carousels/multimedia/PAG_4_IMG_1.webp',
        alt: 'Dynamic live game',
      },
    ],
    backgroundPoints: `10,21 90,5 89,92 5,75`,
    className: 'multimedia',
  },
  {
    icon: 'comic',
    title: 'COMIC WEB',
    subtitle:
      'Interactive comics, providing stories to read or listen to for contextual understanding of basic concepts.',
    images: [
      {
        src: '/img/home/carousels/multimedia/PAG_5_IMG_1.webp',
        alt: 'Interactive comics',
      },
      {
        src: '/img/home/carousels/multimedia/PAG_5_IMG_2.webp',
        alt: 'Interactive comics 2',
      },
    ],
    backgroundPoints: `8,15 90,25 96,75 6,100`,
    className: 'multimedia comic',
  },
  {
    iconComponent: (
      <div className="image-icon-wrapper">
        <Image
          src={
            process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
            '/img/home/carousels/multimedia/PAG_6_IMG_1.webp'
          }
          alt="H.F.V."
          layout="fill"
          objectFit="contain"
        />
      </div>
    ),
    title: 'H.F.V.',
    subtitle:
      'PLUS! Enjoy bonus games to practice key learning concepts like high frequency verbs. A fun way to reinforce these essential skills.',
    images: [
      {
        src: '/img/home/carousels/multimedia/PAG_6_IMG_2.webp',
        alt: 'Skateboard game',
      },
      {
        src: '/img/home/carousels/multimedia/PAG_6_IMG_3.webp',
        alt: 'Concept quest game',
      },
      {
        src: '/img/home/carousels/multimedia/PAG_6_IMG_4.webp',
        alt: 'Bubbles game',
      },
    ],
    backgroundPoints: `8,15 90,25 96,75 6,100`,
    className: 'multimedia hfv',
  },
];
