import Image from 'next/image';
import { StyledPrintable } from './Printable.styled';
import HomeCarousel from '../HomeCarousel/HomeCarousel';
import { StyledActivityIconCircle } from '@/shared/ActivityTypesList/ActivityTypesList.styled';
import ActivityTypeIcon from '@/shared/ActivityTypeIcon/ActivityTypeIcon';
import { Title } from '@/shared/Typography';
import Button from '@/shared/Button/Button';
import Trapezoid from '../Trapezoid/Trapezoid';

const imgFolder =
  process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
  '/img/home/carousels/worksheets';

export default function Printable() {
  return (
    <StyledPrintable>
      <div className="not-only-screen-wrapper">
        <div className="not-only-screen-image">
          <Image
            src={`${imgFolder}/screen-free.png`}
            alt={'But not only screen resources'}
            width={963}
            height={474}
            objectFit="contain"
            layout="fill"
          />
        </div>
      </div>
      <HomeCarousel
        slides={slides}
        renderSlide={({ slide, carouselRef, carouselButtons }, index) => (
          <Slide
            key={index}
            {...slide}
            carouselRef={carouselRef}
            carouselButtons={carouselButtons}
          />
        )}
      />
    </StyledPrintable>
  );
}

function Slide({
  title,
  subtitle,
  subtitle2,
  learnMoreButton,
  mainPanel,
  images,
  carouselRef,
  backgroundDrop,
  backgroundPoints,
  className = '',
  carouselButtons,
}) {
  return (
    <div className={`section ${className}`}>
      <div className="section-content-wrapper">
        <div className="first-row">
          <div
            className={`main-panel ${backgroundDrop ? 'drop-background' : ''}`}
          >
            {mainPanel}
          </div>
          <div className={`section-titles-container`}>
            <div className="section-titles">
              <Title level={2} className="section-title">
                {title}
              </Title>
              <Title level={3} className="section-subtitle">
                {subtitle}
              </Title>
              {learnMoreButton ? (
                <div className="button-container">
                  <Button
                    type="primary"
                    onClick={() => carouselRef.current.next()}
                  >
                    Learn more
                  </Button>
                </div>
              ) : null}
              {carouselButtons}
              {subtitle2 ? (
                <Title level={3} className="section-subtitle section-subtitle2">
                  {subtitle2}
                </Title>
              ) : null}
            </div>
          </div>
        </div>
        <div className={`images-container`}>
          {images.map((image, index) => (
            <div key={index} className="image-wrapper">
              <Image
                src={process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + image.src}
                alt={image.alt}
                layout="fill"
                objectFit="contain"
                style={{ zIndex: image.zIndex }}
              />
            </div>
          ))}
        </div>
        {backgroundPoints && (
          <div className="background">
            <Trapezoid points={backgroundPoints} />
          </div>
        )}
      </div>
    </div>
  );
}

function BigIcon({ icon }) {
  return (
    <div className="big-icon-wrapper">
      <StyledActivityIconCircle type={icon} className="icon">
        <ActivityTypeIcon type={icon} color={'white'} size={45} />
      </StyledActivityIconCircle>
    </div>
  );
}

const slides = [
  {
    title: (
      <>
        Printable Resources
        <br />
        to Reduce Screen Time
      </>
    ),
    subtitle: 'WORKSHEETS, CRAFTS, PICTURE DICTIONARIES, COMICS... AND MORE!',
    learnMoreButton: true,
    mainPanel: (
      <>
        <div className="image-wrapper">
          <Image
            src={`${imgFolder}/page1/02_KIDS.webp`}
            alt={'Kids playing with Rockalingua printable resources'}
            layout="fill"
            objectFit="contain"
          />
        </div>
        <div className="image-wrapper notes">
          <Image
            src={`${imgFolder}/page1/03_NOTASMUSICALES.webp`}
            alt={''}
            layout="fill"
            objectFit="contain"
          />
        </div>
      </>
    ),
    images: [
      {
        src: '/img/home/carousels/worksheets/page1/04_HOJA_1.webp',
        alt: 'Worksheet 1',
        zIndex: 3,
      },
      {
        src: '/img/home/carousels/worksheets/page1/05_HOJA_2.webp',
        alt: 'Worksheet 2',
        zIndex: 2,
      },
      {
        src: '/img/home/carousels/worksheets/page1/06_HOJA_3.webp',
        alt: 'Worksheet 3',
        zIndex: 3,
      },
    ],
    backgroundDrop: true,
    className: 'printable',
  },
  {
    mainPanel: <BigIcon icon={'worksheet'} />,
    title: 'Illustrated print-friendly worksheets.',
    subtitle:
      'With easy exercises to reinforce themes and apply learning in context.',
    images: [
      {
        src: '/img/home/carousels/worksheets/page2/01_HOJA_1.webp',
        alt: 'Worksheet 1',
        zIndex: 3,
      },
      {
        src: '/img/home/carousels/worksheets/page2/03_HOJA_3.webp',
        alt: 'Worksheet 2',
        zIndex: 2,
      },
      {
        src: '/img/home/carousels/worksheets/page2/02_HOJA_2.webp',
        alt: 'Worksheet 3',
        zIndex: 3,
      },
    ],
    backgroundPoints: `0,3 100,7 90,90 6,80`,
    className: 'printable worksheets',
  },
  {
    mainPanel: <BigIcon icon={'pictureDictionary'} />,
    title: 'FLASHCARDS AND PICTURE DICTIONARIES.',
    subtitle2:
      'We make learning exciting by moving beyond basic translation and memorization, focusing instead on immersive methods that connect engaging images with concepts.',
    images: [
      {
        src: '/img/home/carousels/worksheets/page3/01_HOJA_1.webp',
        alt: 'Picture dictionary 1',
        zIndex: 3,
      },
      {
        src: '/img/home/carousels/worksheets/page3/03_FLASHcards_2.webp',
        alt: 'Picture dictionary 2',
        zIndex: 2,
      },
      {
        src: '/img/home/carousels/worksheets/page3/02_FLASHcards_1.webp',
        alt: 'Flashcards',
        zIndex: 3,
      },
    ],

    backgroundPoints: `2,20 90,0 98,50 25,95`,
    className: 'printable picture-dictionaries',
  },
  {
    mainPanel: <BigIcon icon={'shortStory'} />,
    title: (
      <div className="title-with-icon">
        <div className="title-parts">
          <span>SHORT STORIES</span>
          <span>AND CRAFTS!</span>
        </div>
        <BigIcon icon={'craft'} />
      </div>
    ),
    subtitle:
      'Broaden vocabulary and enhance understanding with our fun storyboard printouts.',
    subtitle2:
      "Inspire students' minds with enjoyable paper crafts that develop psychomotor skills and bring learning to life.",
    images: [
      {
        src: '/img/home/carousels/worksheets/page4/01_SHORTstory.webp',
        alt: 'Short story',
        zIndex: 3,
      },
      {
        src: '/img/home/carousels/worksheets/page4/03_CRAFT_2.webp',
        alt: 'Craft 1',
        zIndex: 2,
      },
      {
        src: '/img/home/carousels/worksheets/page4/02_CRAFT_1.webp',
        alt: 'Craft 2',
        zIndex: 3,
      },
    ],
    backgroundPoints: `30,0 95,35 100,80 2,75`,
    className: 'printable short-stories',
  },
];
