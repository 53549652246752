import { StyledHomeCarousel } from './HomeCarousel.styled';
import { Carousel } from 'antd';
import { useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-solid';
import Colors from '@/constants/Colors';
import CarouselButtons from './CarouselButtons/CarouselButtons';

export default function HomeCarousel({ slides = [], renderSlide }) {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideCount = slides.length;

  return (
    <StyledHomeCarousel>
      <Carousel
        className="home-carousel"
        ref={carouselRef}
        dots={false}
        afterChange={setCurrentSlide}
        arrows
        prevArrow={<LeftArrow />}
        nextArrow={<RightArrow />}
      >
        {slides.map((slide, index) =>
          renderSlide
            ? renderSlide(
                {
                  slide,
                  slideCount,
                  currentSlide,
                  carouselRef,
                  carouselButtons: (
                    <CarouselButtons
                      slideCount={slideCount}
                      currentSlide={currentSlide}
                      goTo={carouselRef.current?.goTo}
                    />
                  ),
                },
                index
              )
            : null
        )}
      </Carousel>
    </StyledHomeCarousel>
  );
}

function LeftArrow({ className, style, onClick }) {
  return (
    <ChevronLeft
      className={className}
      style={{ ...style, fill: Colors.lighterGray, height: 40, width: 40 }}
      onClick={onClick}
    />
  );
}

function RightArrow({ className, style, onClick }) {
  return (
    <ChevronRight
      className={className}
      style={{ ...style, fill: Colors.lighterGray, height: 40, width: 40 }}
      onClick={onClick}
    />
  );
}
