import Colors from '@/constants/Colors';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import { pxToVw } from '@/modules/styled/cssUnits';
import styled from 'styled-components';

export const StyledActivityTypesList = styled.div`
  width: 100%;
  margin-top: ${pxToVw(45)};
  display: grid;
  justify-content: space-around;
  grid-gap: ${pxToVw(40)};
  grid-template-columns: repeat(4, 1fr);

  .activity-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    .activity-type-name {
      text-align: center;
      margin-top: ${pxToVw(20)};
      font-weight: bold;
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    margin-top: 50px;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);

    .activity-type {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${MediaQueryBreakpoints.xl}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const StyledActivityIconCircle = styled.div`
  width: ${(props) => (props.width ? props.width + 'px' : pxToVw(65))};
  height: ${(props) => (props.width ? props.height + 'px' : pxToVw(65))};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    Colors.activityTypeColors[props.type] || Colors.lighterGray};

  .more {
    font-size: ${pxToVw(24)};
    color: white;
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    width: ${(props) => props.size * 2.5 || 50}px !important;
    height: ${(props) => props.size * 2.5 || 50}px !important;

    svg {
      transform: scale(1.5) !important;
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.sm}) {
    svg {
      transform: scale(2) !important;
    }
  }
`;
