import Button from '@/shared/Button/Button';
import Image from 'next/image';
import { StyledHowDoesItWorks } from './HowDoesItWork.styled';
import useScrollOverlap from '@/hooks/useScrollOverlap';

export default function HowDoesItWork({ actions }) {
  const { beforeRef, afterRef } = useScrollOverlap();
  return (
    <StyledHowDoesItWorks>
      <div className="section how-does-it-works before" ref={beforeRef}>
        <div className="section-content-wrapper">
          <div className="content">
            <div className="text">
              <h2 className="title">The problems of teaching</h2>
              <p>Lack of engaging resources</p>
              <p>Boring and uninspiring teaching material</p>
              <p>Too much time spent on paperwork and planning</p>
              <p className="emphasized">We do have the solution!</p>
              <div className="buttons">
                <Button type="white" href="/sign-up">
                  Start free trial
                </Button>
                {/* <Button ghost onClick={actions.openPromoModal}>
                  Watch video
                </Button> */}
              </div>
            </div>

            <div className="images-wrapper">
              <div className="title-image">
                <Image
                  src={
                    process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                    '/img/home/parallax/1_WITHout_blanco.svg'
                  }
                  alt="Teaching without Rockalingua"
                  layout="fill"
                />
              </div>
              <div className="big-image">
                <Image
                  src={
                    process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                    '/img/home/parallax/CLASE_WITHOUT_1.webp'
                  }
                  priority
                  alt="Teaching without Rockalingua"
                  layout="fill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlap-container" ref={afterRef}>
        <div className="section how-does-it-works after">
          <div className="section-content-wrapper">
            <div className="content">
              <div className="text">
                <h2 className="title">Empower your teaching</h2>
                <p>
                  Our captivating content guarantees an effective, fun, and
                  engaging learning experience for your students - while arming
                  you with time-saving resources.
                </p>
                <p className="emphasized">
                  Start your journey to Spanish teaching success now!
                </p>
                <div className="more-text">
                  <p></p>
                  <p></p>
                </div>
                <Button type="important" href="/sign-up">
                  Start free trial
                </Button>
              </div>

              <div className="images-wrapper">
                <div className="title-image">
                  <Image
                    src={
                      process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                      '/img/home/parallax/2_WITH.svg'
                    }
                    alt="Teaching without Rockalingua"
                    layout="fill"
                  />
                </div>
                <div className="big-image">
                  <Image
                    src={
                      process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                      '/img/home/parallax/CLASE_WITH_1.webp'
                    }
                    alt="Teaching without Rockalingua"
                    layout="fill"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledHowDoesItWorks>
  );
}
