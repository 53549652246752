import Image from 'next/image';
import Link from 'next/link';
import { StyledSocialNetworks } from './SocialNetworks.styled';

export default function SocialNetworks() {
  return (
    <StyledSocialNetworks>
      <span>
        <Link href="https://www.facebook.com/Rockalingua">
          <a>
            <Image
              src={
                process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                '/img/media-icon-facebook.svg'
              }
              alt="Rockalingua logo"
              layout="fill"
            />
          </a>
        </Link>
      </span>
      <span>
        <Link href="https://www.instagram.com/rockalingua">
          <a>
            <Image
              src={
                process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                '/img/media-icon-instagram.svg'
              }
              alt="Instagram"
              layout="fill"
            />
          </a>
        </Link>
      </span>
      <span>
        <Link href="http://www.youtube.com/user/Rockalingua">
          <a>
            <Image
              src={
                process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                '/img/media-icon-youtube.svg'
              }
              alt="Rockalingua logo"
              layout="fill"
            />
          </a>
        </Link>
      </span>
    </StyledSocialNetworks>
  );
}
