import styled from 'styled-components';
import { StyledPublicPage } from './PublicPage.styled';
import { pxToVw, pxToVwFromXCenter } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import Colors from '@/constants/Colors';

const imgFolder = process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + '/img';

export const WithBigDropLeftBg = styled(StyledPublicPage)`
  background-image: url('${imgFolder}/public-page/drop.svg');
  background-repeat: no-repeat;
  background-position: ${pxToVwFromXCenter(-750)} ${pxToVw(-340)};
  background-size: ${pxToVw(800)};
`;

export const WithTwoDropsBg = styled(StyledPublicPage)`
  background-image: url('${imgFolder}/public-page/drop.svg'),
    url('${imgFolder}/public-page/drop.svg');
  background-repeat: no-repeat;
  background-position: calc(50vw - ${pxToVw(750)}) ${pxToVw(-250)};
  /*, calc(50vw + ${pxToVw(215)}) ${pxToVw(-220)}; */
  background-size: ${pxToVw(600)}; /*, ${pxToVw(600)};*/
`;

export const WithHugeDropLightDarkOneRightBg = styled(StyledPublicPage)`
  position: relative;
  background-color: transparent;
  background-image: url('${imgFolder}/public-page/drop-darker.svg'),
    url('${imgFolder}/public-page/drop.svg');
  background-repeat: no-repeat;
  background-size: ${pxToVw(630)}, ${pxToVw(10000)};
  background-position:
    ${pxToVwFromXCenter(0)} ${pxToVw(-180)},
    ${pxToVwFromXCenter(-5000)} calc(100% - ${pxToVw(950)});

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    background-size: ${pxToVw(630)}, ${pxToVw(20000)};
    background-position:
      ${pxToVwFromXCenter(0)} ${pxToVw(-180)},
      ${pxToVwFromXCenter(-10000)} calc(100% - ${pxToVw(2700)});
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.sm}) {
    background-size: ${pxToVw(630)}, ${pxToVw(40000)};
    background-position:
      ${pxToVwFromXCenter(0)} ${pxToVw(-180)},
      ${pxToVwFromXCenter(-10000)} calc(100% - ${pxToVw(2700)});
  }
`;

export const WithHugeDarkDropRightBg = styled(StyledPublicPage)`
  background-color: ${Colors.backgroundPaleBlue};
  background-image: url('${imgFolder}/public-page/drop-darker.svg');
  background-repeat: no-repeat;
  background-size: 150vw;
  background-position: 47vw center;
`;

export const LightBackgroundPage = styled(StyledPublicPage)`
  background-color: ${Colors.backgroundPaleBlue};
`;
