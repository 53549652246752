import { Title } from '@/shared/Typography';
import { StyledLms } from './Lms.styled';
import Image from 'next/image';
import UncollapsibleDiv from '@/shared/UncollapsibleDiv/UncollapsibleDiv';
import { CheckOutlined } from '@ant-design/icons';

export default function Lms() {
  return (
    <StyledLms className="section lms">
      <div className="section-content-wrapper">
        <div className="section-title-wrapper">
          <Title level={2} className="section-title">
            Easily monitor students' work <br />{' '}
            <span className="ml">with our progress tracking</span> reports
          </Title>
        </div>

        <UncollapsibleDiv>
          <div className="details">
            <div className="details-title">
              Organize your work quickly and efficiently, reducing planning time
              and paperwork with our 'Pro' classroom tools.
            </div>
            <div className="details-features">
              <div className="feature">
                Create classes
                <CheckOutlined />
              </div>
              <div className="feature">
                Assign work to students <CheckOutlined />
              </div>
              <div className="feature">
                Keep track of students work and progress <CheckOutlined />
              </div>
              <div className="feature">
                Unlock the content that you want each class to see/access{' '}
                <CheckOutlined />
              </div>
            </div>
          </div>
        </UncollapsibleDiv>
        <div className="image-wrapper">
          <Image
            src={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/illustrations/lms-teacher.png?v=2'
            }
            width={1200}
            height={684}
            layout="responsive"
            alt="Track students' progress"
          />
        </div>
      </div>
    </StyledLms>
  );
}
