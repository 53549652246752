import styled from 'styled-components';
import { maxWidth, pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import Colors from '@/constants/Colors';

const sectionPadding = `calc((100% - ${maxWidth}px) / 2);`;

const titleSize = {
  width: 703,
  height: 106,
};
const imageSize = {
  width: 1385,
  height: 634,
};

const factor = 0.5;
const sectionHeight = `calc(${
  (imageSize.height + titleSize.height) * factor
}px)`;

const mdSectionHeight = '680px';

export const StyledHowDoesItWorks = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: ${pxToVw(80)};
  height: ${sectionHeight};
  display: flex;
  flex-direction: column;

  .section.how-does-it-works {
    margin-top: 0;
    flex: 1;

    &.before {
      background: rgb(185, 198, 210);
      background: linear-gradient(
        0deg,
        rgba(185, 198, 210, 1) 0%,
        rgba(140, 170, 178, 1) 26%,
        rgba(104, 145, 152, 1) 39%,
        rgba(89, 114, 119, 1) 46%,
        rgba(107, 133, 138, 1) 49%,
        rgba(182, 219, 227, 1) 100%
      );
      color: white;

      .title {
        color: white;
        z-index: 1;
      }

      p {
        z-index: 1;
      }

      .ant-btn {
        z-index: 1;
      }
    }

    &.after {
      z-index: 2;
      background: rgb(196, 255, 251);
      background: linear-gradient(
        0deg,
        rgba(196, 255, 251, 1) 0%,
        rgba(0, 250, 251, 1) 26%,
        rgba(0, 235, 204, 1) 43%,
        rgba(0, 235, 204, 1) 46%,
        rgba(0, 255, 231, 1) 49%,
        rgba(0, 255, 199, 1) 100%
      );
      color: ${Colors.pink};

      .title {
        color: ${Colors.pink};
        z-index: 2;
      }

      p {
        font-weight: bold;
        z-index: 2;
      }

      .ant-btn {
        z-index: 2;
      }
    }

    .section-content-wrapper {
      flex: 1;
      height: 100%;
      padding-top: 40px;

      .content {
        padding: 0 ${sectionPadding};
        height: 100%;
        width: 100%;
        display: flex;

        .text {
          flex: 1;
          display: flex;
          flex-direction: column;

          .title {
            margin-bottom: ${pxToVw(20)};
          }

          .buttons {
            display: flex;
            flex-direction: column;
            gap: ${pxToVw(10)};
            margin-top: ${pxToVw(10)};
          }
        }
        .images-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title-image {
            width: ${titleSize.width * factor}px;
            height: ${titleSize.height * factor}px;
            position: relative;
            margin-bottom: -30px;
          }
          .big-image {
            width: ${imageSize.width * factor}px;
            height: ${imageSize.height * factor}px;
            position: relative;
          }
        }
      }
    }
  }

  .overlap-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    overflow: hidden;
    z-index: 50;
  }

  @media only screen and (min-width: ${MediaQueryBreakpoints.md}) and (max-width: ${MediaQueryBreakpoints.xl}) {
    .section-content-wrapper .content {
      .text {
        width: 40%;
      }
      .images-wrapper {
        width: 60%;
      }
    }
  }

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    margin: 70px 0;
    height: ${mdSectionHeight};
    .section.how-does-it-works {
      .section-content-wrapper {
        height: ${mdSectionHeight};
        .content {
          flex-direction: column;

          .text {
            width: 100%;
            .title {
              font-size: var(--font-size-h1);
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
`;
