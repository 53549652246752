import React, { useState, useEffect, useRef } from 'react';

export default function UncollapsibleDiv({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [height, setHeight] = useState('0px');
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const middleOfScreen = windowHeight / 2;

        if (rect.top <= middleOfScreen) {
          setIsCollapsed(false);
        } else if (rect.top > middleOfScreen + 100) {
          setIsCollapsed(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setHeight(isCollapsed ? '0px' : `${ref.current.scrollHeight}px`);
    }
  }, [isCollapsed]);

  return (
    <div
      ref={ref}
      style={{
        height,
        overflow: 'hidden',
        transition: 'height 0.3s ease',
      }}
    >
      {children}
    </div>
  );
}
