import Button from '@/shared/Button/Button';
import { Title } from '@/shared/Typography';
import Image from 'next/image';
import { StyledMember } from './Member.styled';
import Stamps from './Stamps';
import SocialNetworks from './SocialNetworks';
import ScrollAppearingDiv from '@/shared/ScrollAppearingDiv/ScrollAppearingDiv';
import useBrightness from './useBrightness.hook';

export default function Member() {
  const { imageRef, brightness } = useBrightness();
  return (
    <StyledMember className="section member">
      <div className="section-content-wrapper">
        <div className="text-wrapper">
          <Title level={2} className="section-title">
            Become a member
          </Title>
          <Title level={3} className="section-subtitle">
            Get unlimited access to our content library
          </Title>
          <Button type="important" href="/sign-up" size="normal">
            Sign up
          </Button>
        </div>
        <div className="member-image-wrapper" ref={imageRef}>
          <Image
            src={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/illustrations/member-new.webp'
            }
            alt="Become a member"
            layout="responsive"
            width="1280"
            height="624"
            objectFit="contain"
            style={{ filter: `brightness(${brightness}%)` }}
          />
        </div>
        <div className="logos-container">
          <Stamps />
          <SocialNetworks />
        </div>

        <ScrollAppearingDiv className="sun">
          <Image
            src={
              process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
              '/img/home/sun.webp'
            }
            alt="Sun"
            layout="fill"
            objectFit="contain"
          />
        </ScrollAppearingDiv>
      </div>
    </StyledMember>
  );
}
